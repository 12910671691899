import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Text,
  VStack,
  Link,
  Divider,
  useColorMode,
  Flex,
} from '@chakra-ui/react';
import Page from '../components/reusable/layout/page';
import Content from '../components/reusable/layout/content';
import PageHeader from '../components/reusable/layout/page-header';
import PayPalDonateButton from '../components/reusable/paypal';
import Blog from '../components/reusable/blog';

const About: React.FunctionComponent = () => {
  const { colorMode } = useColorMode();
  const emailAddress = 'seating.quest@gmail.com';
  const mediumLink =
    'https://medium.com/@philippwostry/how-to-build-a-seating-plan-with-seatingquest-d4fb388149c6?source=friends_link&sk=85be6d54bbe896d0d1e5b889a961cd71';
  return (
    <Page showNav={true}>
      <Content>
        <PageHeader>About SeatingQuest</PageHeader>

        <Card variant={colorMode === 'light' ? 'outlinetline' : 'elevated'} marginTop='4'>
          <CardHeader>
            <Text fontSize='xl' as='b'>
              General
            </Text>
          </CardHeader>
          <CardBody>
            <VStack spacing='4' align='start'>
              <Text>
                SeatingQuest is an easy-to-use tool to help you create the perfect seating plan for
                your important event - and fast!
              </Text>
              <Box>
                <Text fontSize='lg' as='b'>
                  Contact Us
                </Text>
                <Text>
                  If you are reading this, you are among our first - and thus most highly valued -
                  users! This also means that your insights and feedback are invaluable, and,
                  unfortunately, that you may run into a bug from time to time.
                </Text>
                <Text>
                  To get in touch with us for a support request or to leave feedback, do not
                  hesitate to contact us by email at:{' '}
                  <Link href={`mailto:${emailAddress}`} color='teal.500' textDecoration='underline'>
                    {emailAddress}
                  </Link>
                </Text>
              </Box>
              <Box>
                <Text fontSize='lg' as='b'>
                  Data and Privacy
                </Text>
                <Text>
                  We designed SeatingQuest to respect your privacy with no risk to your personal
                  information. We do not collect or store any personal information about you or your
                  guests, apart from your name and email address when you reach out to us for
                  feedback or support. You can find our official privacy policy here:{' '}
                  <Link color='teal.500' href='/privacy-policy.html' target='_blank' isExternal>
                    Privacy Policy
                  </Link>
                </Text>
              </Box>
            </VStack>
          </CardBody>
        </Card>

        <Divider marginBottom={8} />

        <Card variant={colorMode === 'light' ? 'outlinetline' : 'elevated'} marginTop='4'>
          <CardHeader>
            <Text fontSize='xl' as='b'>
              How to use SeatingQuest
            </Text>
          </CardHeader>
          <CardBody>
            <Box marginBottom='15px'>
              <Text>
                We wrote an article about How to use SeatingQuest. You can find it on{' '}
                <Link color='teal.500' href={mediumLink} target='_blank' isExternal>
                  medium.com
                </Link>{' '}
              </Text>
            </Box>

            <Blog />
          </CardBody>
        </Card>

        <Flex justifyContent='center' marginTop='4'>
          <PayPalDonateButton />
        </Flex>
      </Content>
    </Page>
  );
};

export default About;
