import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Heading, Image, Link, Spinner, Text, VStack } from '@chakra-ui/react';

const Blog: React.FunctionComponent = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const address = 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*yVvMjmT6A29rU9uxao0z1Q.jpeg'

  const getPostData = () => {
    axios
      .get(
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@philippwostry'
      )
      .then((res) => {
        setPosts(res.data.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPostData();
  }, []);

  return (
    <VStack spacing={6} align='center'>
      {loading ? (
        <Spinner size='lg' />
      ) : posts.length > 0 ? (
        posts.map((post: any, index) => (
          <Box
            key={index}
            borderWidth='1px'
            borderRadius='lg'
            overflow='hidden'
            padding='4'
            boxShadow='sm'
            _hover={{ boxShadow: 'md' }}
            width='auto'
            maxWidth='800px'
          >
            <Heading as='h3' size='md' marginBottom={2}>
              <Link href={post.link} color='teal.500' isExternal>
                {post.title}
              </Link>
            </Heading>
            <Text fontSize='sm' color='gray.500' marginBottom={4}>
              Published on {new Date(post.pubDate).toLocaleDateString()}
            </Text>
            <Image
              src={address}
              alt={post.title}
              borderRadius='md'
              marginBottom={4}
              maxHeight='400px'
              objectFit='cover'
            />
            <Text noOfLines={6}>{post.description.replace(/<[^>]*>/g, '')}</Text>
          </Box>
        ))
      ) : (
        <Text>No blog posts available.</Text>
      )}
    </VStack>
  );
};

export default Blog;