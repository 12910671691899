import { Box, Button, Center, Heading, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import DarkModeToggle from '../components/reusable/layout/color-mode-toggle';
import { FOOTER_HEIGHT } from '../components/reusable/layout/footer';
import Page from '../components/reusable/layout/page';
import useMobile from '../hooks/useMobile';

const Home: React.FunctionComponent = () => {
  const isMobile = useMobile();

  return (
    <Page showNav={false}>
      <Box position='absolute' top='1rem' right='1rem'>
        <DarkModeToggle />
      </Box>
      <Center
        height={`calc(100vh - ${FOOTER_HEIGHT}px)`}
        flexDir='column'
        width='100%'
        textAlign='center'
      >
        <Heading lineHeight={isMobile ? '3.0' : '1.3'} size='xl' fontFamily='BarokahSignature'>
          Welcome to SeatingQuest
        </Heading>
        <Text marginTop={7} marginBottom={2}>
          Build your seating plan in minutes!
        </Text>
        <HStack>
          <Link to='/guests'>
            <Button variant='outline'>Get Started</Button>
          </Link>
          <Link to='/about'>
            <Button variant='outline'>How to use SeatingQuest</Button>
          </Link>
        </HStack>
      </Center>
    </Page>
  );
};

export default Home;
